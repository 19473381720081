import { MotionProps, Variants } from 'framer-motion';

const easing = [0.175, 0.25, 0.32, 0.36];

export const TransitionPowerOne = { duration: 0.3, ease: easing };
export const TransitionPowerThree = {
  y: { stiffness: 1000 },
};

export const slideUp: MotionProps = {
  initial: {
    opacity: 0,
    y: 100,
  },
  animate: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: 100,
  },
  transition: {
    ...TransitionPowerOne,
  },
};

export const rotate: Variants = {
  initial: {
    rotateZ: 0,
  },
  animate: {
    rotateZ: 290,
    transition: {
      ...TransitionPowerOne,
      duration: 0.8,
    },
  },
};

export const slideDown: MotionProps = {
  initial: {
    opacity: 0,
    y: -100,
  },
  animate: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: -100,
  },
  transition: {
    ...TransitionPowerOne,
  },
};

export const slideRight: MotionProps = {
  initial: {
    opacity: 0,
    x: -100,
  },
  animate: {
    opacity: 1,
    x: 0,
  },
  exit: {
    opacity: 0,
    x: -100,
  },
  transition: {
    ...TransitionPowerOne,
  },
};

export const slideLeft: MotionProps = {
  initial: {
    opacity: 0,
    x: 100,
  },
  animate: {
    opacity: 1,
    x: 0,
  },
  exit: {
    opacity: 0,
    x: 100,
  },
  transition: {
    ...TransitionPowerOne,
  },
};

export const scaleUp: MotionProps = {
  initial: {
    opacity: 0,
    scale: 0,
  },
  animate: {
    opacity: 1,
    scale: 1,
  },
  exit: {
    scale: 0.5,
    opacity: 0,
  },
  transition: {
    ...TransitionPowerOne,
  },
};

// variants
export const toggleSlide: Variants = {
  hidden: {
    left: '-100%',
    transition: {
      ...TransitionPowerOne,
    },
  },
  animate: {
    left: '0%',
    transition: {
      ...TransitionPowerOne,
    },
  },
};

export const slideDownVariant: Variants = {
  hidden: {
    opacity: 0,
    y: -40,
    transition: {
      ...TransitionPowerOne,
    },
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      ...TransitionPowerOne,
    },
  },
};

export const zIndexVariant: Variants = {
  hidden: {
    zIndex: -123123,
    opacity: 0,
    display: 'none',
    transition: {
      delay: 0.9,
    },
  },
  visible: {
    opacity: 1,
    display: 'flex',
    zIndex: 1231231,
  },
};

// has children
export const ListsVariants: Variants = {
  initial: {
    transition: {
      delayChildren: 0.6,
      staggerChildren: 0.1,
      staggerDirection: 1,
    },
  },
  animate: {
    transition: {
      delayChildren: 0.6,
      staggerChildren: 0.1,
    },
  },
  exit: {
    transition: {
      delayChildren: 0.6,
      staggerChildren: 0.1,
      staggerDirection: 1,
    },
  },
};

export const ListsSlideRightStagger: Variants = {
  initial: {
    x: -100,
    opacity: 0,
    transition: {
      y: { stiffness: 100 },
    },
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      ...TransitionPowerOne,
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  exit: {
    x: -100,
    opacity: 0,
    transition: {
      ...TransitionPowerOne,
      y: { stiffness: 100 },
    },
  },
};
