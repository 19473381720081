import { useEffect, useRef, useState } from 'react';
import BGDESKTOP_IMG from './assets/images/bg-desktop.jpg';
import BGMOBILE_IMG from './assets/images/bg-mobile.jpg';
import NATALCHART_IMG from './assets/images/natal-chart.png';
import { Popup } from './components';
import { pieSlices } from './pieSlices';
import AUDIO1 from './assets/audio/01 Mind Games (intro)_KJA M2_(eq 9-29) 96Khz 24Bit.mp3';

export type PieSlicesTypes = {
  id: string;
  containerClass: string;
  title: string;
  description: string;
  logo: string;
};

const App = () => {
  const [zodiacSign, setZodiacSign] = useState<PieSlicesTypes>(pieSlices[0]);
  const [notificationPopup, setNotificationPopup] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const audio: any = useRef(null);
  const astroItem: any = useRef(null);

  useEffect(() => {
    window.addEventListener('load', () => {
      setIsLoaded(document.readyState === 'complete');
    });
  }, [isLoaded]);

  const handleClick = (value: PieSlicesTypes) => {
    setZodiacSign(value); // sets the value of the zodiac sign
    setNotificationPopup(true);
  };

  const handleLinkClick = () => {
    const bgm = audio.current as HTMLAudioElement;
    bgm.currentTime = 0;
    bgm.play();
  };

  const handleMouseEnter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.currentTarget.classList.remove('rotating');
  };

  const handleMouseOut = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.currentTarget.classList.add('rotating');
  };

  return (
    <div className="astro w-full h-screen fixed top-0 left-0 overflow-hidden">
      <audio ref={audio} playsInline>
        <source src={AUDIO1} type="audio/mpeg" />
      </audio>
      {!isLoaded ? null : (
        <div className="h-full w-full">
          <div className="fixed top-0 left-0 min-h-screen w-full -z-10">
            <img
              src={BGDESKTOP_IMG}
              alt=""
              className="h-screen w-full object-cover hidden md:block"
            />
            <img
              src={BGMOBILE_IMG}
              alt=""
              className="w-full h-screen object-cover block md:hidden"
            />
          </div>

          <div className="flex flex-col justify-center items-center h-full -mt-16 sm:-mt-10 md:mt-0">
            <div className="z-10 flex flex-col justify-center text-center">
              <h2 className="text-white text-2xl md:text-5xl font-bold">JORDY "MIND GAMES"</h2>
              <div className="mt-5">
                <a
                  href="https://jordy.ffm.to/mindgames"
                  target="_blank"
                  rel="noreferrer"
                  className="bg-white text-black py-2 px-10 border border-white tracking-wide hover:text-white hover:bg-transparent"
                  onClick={handleLinkClick}
                >
                  STREAM
                </a>
              </div>
            </div>
            <div
              onPointerOut={handleMouseOut}
              onPointerOver={handleMouseEnter}
              ref={astroItem}
              className="astro-item rotating relative flex justify-center items-center mt-5 transform transition-all"
            >
              <img src={NATALCHART_IMG} alt="natal chart" className="astro-img" />
              {pieSlices.map((item) => (
                <div key={item.id} id={item.id} className={item.containerClass}>
                  <button className="pie-name" onClick={() => handleClick(item)}>
                    name
                  </button>
                  <button className="pie-animal" onClick={() => handleClick(item)}>
                    animal
                  </button>
                  <button className="pie-sign" onClick={() => handleClick(item)}>
                    sign
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      <Popup
        zodiac={zodiacSign}
        setNotificationPopup={setNotificationPopup}
        isActive={notificationPopup}
      />
    </div>
  );
};

export default App;
