import { motion } from 'framer-motion';
import { PieSlicesTypes } from '../../App';
import { slideDownVariant, zIndexVariant } from '../FramerMotion/FramerMotion';
import styled from 'styled-components';
import BGDESKTOP_IMG from '../../assets/images/bg-desktop.png';

const Box = styled(motion.div)`
  background-image: url('${BGDESKTOP_IMG}');
`;

interface PopupProps {
  zodiac: PieSlicesTypes;
  isActive: boolean;
  setNotificationPopup: (val: boolean) => void;
}

export default function Popup({ zodiac, isActive, setNotificationPopup }: PopupProps) {
  return (
    <motion.div
      animate={isActive ? 'visible' : 'hidden'}
      variants={zIndexVariant}
      className="fixed top-0 left-0 w-full  h-screen opacity-0 flex justify-center items-center"
    >
      <div
        className="z-0 fixed top-0 left-0 h-screen w-full opacity-0"
        onClick={() => setNotificationPopup(false)}
      />
      <Box
        animate={isActive ? 'visible' : 'hidden'}
        variants={slideDownVariant}
        className="px-5 md:px-16 py-10 shadow-md relative text-center w-5/6 md:w-3/4"
      >
        <div className="mb-5 relative flex justify-center items-center ">
          <img src={zodiac.logo} alt="" className="w-24 h-24 opacity-30" />
          <span className="text-2xl font-bold absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 whitespace-nowrap">
            {zodiac.title}
          </span>
        </div>
        <span className="text-xl">{zodiac.description}</span>
        <button
          onClick={() => setNotificationPopup(false)}
          className="top-2 font-bold right-5 text-gray-400 hover:text-black absolute"
        >
          x
        </button>
      </Box>
    </motion.div>
  );
}
