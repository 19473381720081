import aquarius_img from './assets/images/signs/aquarius 3.png';
import aries_img from './assets/images/signs/aries 3.png';
import cancer_img from './assets/images/signs/cancer 3.png';
import capricorn_img from './assets/images/signs/Capricorn 3.png';
import gemini_img from './assets/images/signs/gemini 3.png';
import leo_img from './assets/images/signs/Leo 3.png';
import libra_img from './assets/images/signs/Libra 3.png';
import pisces_img from './assets/images/signs/pisces 3.png';
import sagittarius_img from './assets/images/signs/sagittarius 3.png';
import scorpio_img from './assets/images/signs/Scorpio 3.png';
import taurus_img from './assets/images/signs/taurus 3.png';
import virgo_img from './assets/images/signs/virgo 3.png';
import { PieSlicesTypes } from './App';

export const pieSlices: PieSlicesTypes[] = [
  {
    id: 'capricorn',
    containerClass: 'capricorn pie-slice',
    title: 'BIMH - Capricorn',
    description:
      'BIMH is Capricorn because they tend to create high expectations that aren’t always met.',
    logo: capricorn_img,
  },
  {
    id: 'sagitarius',
    containerClass: 'sagitarius pie-slice',
    title: 'Long Distance - Sagittarius ',
    description: 'Long Distance is Sagittarius because they’re known to travel and take risks etc.',
    logo: sagittarius_img,
  },
  {
    id: 'scorpio',
    containerClass: 'scorpio pie-slice',
    title: 'Sticks - Scorpio',
    description:
      'Sticks is Scorpio because they can get a little spicy but also are extremely sensitive.',
    logo: scorpio_img,
  },
  {
    id: 'libra',
    containerClass: 'libra pie-slice',
    title: 'Trevor - Libra',
    description:
      ' Trevor is Libra because Libras  can have perfectionist tendencies and always want to be a better version of themselves. If they see someone that’s “perfect”, they want to capture that quality.',
    logo: libra_img,
  },
  {
    id: 'virgo',
    containerClass: 'virgo pie-slice',
    title: 'Dressed Up - Virgo',
    description:
      'Dressed Up is Virgo because they also tend to be obsessed with their image but more in a personal and private way. Tend to hide insecurities.',
    logo: virgo_img,
  },
  {
    id: 'leo',
    containerClass: 'leo pie-slice',
    title: 'Delete Me - Leo',
    description:
      'Delete Me is Leo because Leo’s tend to be very obsessed with their image but also would only want someone who deserves them; don’t have time for f boys.',
    logo: leo_img,
  },
  {
    id: 'cancer',
    containerClass: 'cancer pie-slice',
    title: 'IJWBL - Cancer',
    description: 'IJWBL is cancer because cancers are gentle creatures who just want to be adored.',
    logo: cancer_img,
  },
  {
    id: 'gemini',
    containerClass: 'gemini pie-slice',
    title: 'Mind Games - Gemini',
    description:
      'Mind Games is Gemini because of the two-sidedness that comes with someone playing with you or you playing with someone.',
    logo: gemini_img,
  },
  {
    id: 'taurus',
    containerClass: 'taurus pie-slice',
    title: 'South Dakota - Taurus',
    description:
      'South Dakota is Taurus because at the end of the day a Taurus thrives in nature and needs alone/quiet time to recharge.',
    logo: taurus_img,
  },
  {
    id: 'aries',
    containerClass: 'aries pie-slice',
    title: 'If He’s In Your Bed - Aries',
    description:
      'If He’s In Your Bed is Aries because they love physical touch and tend to be very spontaneous even for reasons that aren’t worth it.',
    logo: aries_img,
  },
  {
    id: 'pisces',
    containerClass: 'pisces pie-slice',
    title: 'Till - Pisces',
    description:
      'Till It Hurts is Pisces because Pisces can be hard to love but have so much love to give ',
    logo: pisces_img,
  },
  {
    id: 'aquarius',
    containerClass: 'aquarius pie-slice',
    title: 'Tomorrow - Aquarius',
    description:
      'Tomorrow is Aquarius because they always want to be improving themselves for a better tomorrow and get caught up in the future.',
    logo: aquarius_img,
  },
];
